import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TextInput from '../common/controls/TextInput';
import TextNumericInput from '../common/controls/TextNumericInput';
import SelectInput from '../common/controls/SelectInput';
import DateInputGeneral from '../common/controls/DateInputGeneral';
import GenderInput from '../common/controls/GenderInput';
import AddressInput from '../common/controls/AddressInput';
import PasswordInput from '../common/controls/PasswordInput';
import LoginTermsAndConditions from '../login/LoginTermsAndConditions';
import * as pages from '../shared/pages';
import * as funcs from '../shared/functions';

import * as ImagesPath from '../../config/imagesPath';
import * as RoutesVariables from '../../config/routesVariables';

import {initTonikForms} from '../../tonik/shared/forms';

const $ = window.$;

const ErrorLine = ({message}) =>
{
  return (

    <div className='form-group '>

      <label className="control-label col-sm-4"></label>
      <div class="alert alert-danger" role="alert" dangerouslySetInnerHTML={funcs.createMarkup(message)}>
      </div>
    </div>
  )
};


// const RegistrationFormFooter = ({onSave, onCancel, textVariants, errors, saving, saveText, cancelText, diplaySavedMessage}) =>
// {
//   var footerContent = null;

//   if (onCancel) {
//     footerContent =

//     <div className='form-group'>
//       <label className="control-label col-sm-4"></label>

//     <div className="field col-sm-6 col-sm-offset-right-2">

//        <button type="submit"
//         onClick={onSave}
//         disabled={saving}
//         className={saving?"btn btn-default  btn-block loading":"btn btn-default"}>
//         {saveText}
//        </button>

//        <button type="button"
//          onClick={onCancel}
//          className="btn btn-default cancel xs-ml-15">
//          {cancelText}
//         </button>

//        <span className="hidden-sm hidden-md hidden-lg"><br /></span>
//        <span className={errors.hasError?"error-message registration-form-message-span sm-ml-10 xs-pt-10 sm-pt-10 sm-pt-0":"hidden"} >{textVariants[pages.REGISTER + textVariants.textVariantSeparator + "validation_error"]}</span>

//        <span className={diplaySavedMessage?'text-left registration-form-message-span sm-ml-10 xs-pt-15':'hidden'}>{textVariants[pages.REGISTER + textVariants.textVariantSeparator + "saved_message"]}</span>
//      </div>
//     </div>
//   }
//   else {
//     footerContent =
//     <div className='form-group'>
//       <label className="control-label col-sm-4"></label>

//     <div className="field col-sm-6 col-sm-offset-right-2">

//        <button type="submit"
//         onClick={onSave}
//         disabled={saving}
//         className={saving?"btn btn-default  btn-block loading":"btn btn-default"}>
//         {saveText}
//        </button>

//        <span className="hidden-sm hidden-md hidden-lg"><br /></span>
//       <div className={errors.hasError?"error-message registration-form-message-span sm-ml-10 xs-pt-10 sm-pt-10 sm-pt-0":"hidden"}>
//         <span>{textVariants[pages.REGISTER + textVariants.textVariantSeparator + "validation_error"]}</span>
//         <br />
//         <span dangerouslySetInnerHTML={funcs.createMarkup(textVariants[pages.REGISTER + textVariants.textVariantSeparator + "validation_error_instruction"])} />
//       </div>

//        <span className={diplaySavedMessage?'text-left registration-form-message-span sm-ml-10 xs-pt-15':'hidden'}>{textVariants[pages.REGISTER + textVariants.textVariantSeparator + "saved_message"]}</span>
//      </div>
//     </div>
//   }

//   return (footerContent);
// };

//const RegistrationForm = ({customer, onSave, onChange, saving, errors, onServiceError, textVariants, diplaySavedMessage, onCancel, , omitFields, saveText}) => {




// class RegistrationFormPlain extends React.Component {

//   constructor(props, context){
//       super(props, context);

//   }

//   componentDidMount() {

//   }

//   componentDidUpdate(prevProps, prevState){
//   }


// render(){

//     let {customer, onSave, onChange, saving, errors, onServiceError, textVariants, diplaySavedMessage, onCancel, omitFields, saveText} = this.props;

//     return (
//       <>
//               <form method="POST">
//                 <div className="row">
//                   <div className="form-label-group col-12">
//                     <TextInput
//                       name="firstName"
//                       id="firstName"
//                       placeholder={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "first_name_plh"]}
//                       value={customer.firstName}
//                       label={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "first_name_label"]}
//                       onChange={onChange}
//                       error={errors.firstName}
//                       groupClass="form-control-wrap" />
//                   </div>
//                   <div className="form-label-group col-12">

//                     <TextInput
//                       name="middleName"
//                       id="middleName"
//                       placeholder={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "middle_name_plh"]}
//                       value={customer.middleName}
//                       label={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "middle_name_label"]}
//                       onChange={onChange}
//                       error={errors.middleName}
//                       groupClass="form-control-wrap" />
//                   </div>
//                   <div className="form-label-group col-12">
//                     <TextInput
//                       name="lastName"
//                       id="lastName"
//                       placeholder={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "last_name_plh"]}
//                       value={customer.lastName}
//                       label={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "last_name_label"]}
//                       onChange={onChange}
//                       error={errors.lastName} 
//                       groupClass="form-control-wrap"/>
//                   </div>
//                   <div className="form-label-group col-12">
//                     <TextInput
//                       name="email"
//                       id="email"
//                       placeholder={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "email_plh"]}
//                       value={customer.email}
//                       label={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "email_label"]}
//                       onChange={onChange}
//                       error={errors.email}
//                       toolTip={textVariants[pages.REGISTER+ textVariants.textVariantSeparator + "email_tooltip"]}
//                       shouldRender={!omitFields || !omitFields["email"]} 
//                       type="email"
//                       groupClass="form-control-wrap"/>
//                   </div>
//                   <div className="form-label-group col-12">

//                     <TextNumericInput
//                       name="mobile"
//                       id="mobile"
//                       placeholder={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "mobile_plh"]}
//                       value={customer.mobile}
//                       label={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "mobile_label"]}
//                       onChange={onChange}
//                       error={errors.mobile} 
//                       groupClass="form-control-wrap"/>
//                   </div>
//                   <div className="form-label-group col-12">
//                       <DateInputGeneral
//                         customer={customer}
//                         error={errors.dob}
//                         textVariants={textVariants}
//                         onChange={onChange} 
//                         groupClass="form-control-wrap"/>
//                   </div>
//                   <div className="col-12">

//                     <GenderInput
//                       name="gender"
//                       id="gender"
//                       value={customer.gender}
//                       label={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "gender_label"]}
//                       onChange={onChange}
//                       error={errors.gender} />

//                   </div>
//                 </div>
//         </form>
//         {errors && errors["global"] && <ErrorLine message={errors["global"]} /> }
//         </>
//         );
//       }
//  }

// RegistrationFormPlain.propTypes = {
//     customer: PropTypes.object.isRequired,
//     onSave: PropTypes.func.isRequired,
//     onChange: PropTypes.func.isRequired,
//     onServiceError: PropTypes.func,
//     textVariants: PropTypes.object.isRequired,
//     saving: PropTypes.bool,
//     errors: PropTypes.object,
//     diplaySavedMessage: PropTypes.bool,
//     onCancel: PropTypes.func,
//     omitFields: PropTypes.object,
//     saveText: PropTypes.string.isRequired
// };

// const RegistrationFormSideFeatures = ({pageVariation}) => {

// let extraText = pageVariation == "savvy"? "Being credit savvy is quick! ":"";

// return (
//   <div className="register-feature">
//             <div className="  visible-lg ">
//                 <div className="container-fluid">
//                     <div className="row">
//                       <div className="col-xs-4 text-right"><img className="register-feature-img" src={ImagesPath.ImgRegistration_IconSafe} /></div>
//                       <div className="col-xs-8"> <h5>Safe &amp; Secure</h5>
//                        <p>We connect directly to Equifax, Australia’s leading credit bureau, to check your identity and ensure only you can access your credit score.</p></div>
//                     </div>
//                     <div className="row">
//                       <div className="col-xs-4 text-right"><img className="register-feature-img" src={ImagesPath.ImgRegistration_IconDiamond} /></div>
//                       <div className="col-xs-8"> <h5>Won't affect your credit file</h5>
//                        <p>Accessing your credit score using this service won't negatively impact your credit file.</p></div>
//                     </div>
//                     <div className="row">
//                       <div className="col-xs-4 text-right"><img className="register-feature-img" src={ImagesPath.ImgRegistration_IconClock} /></div>
//                       <div className="col-xs-8"> <h5>Fast and totally free!</h5>
//                        <p>{extraText + "Get your credit score in 60 seconds, no credit card required."} </p></div>
//                     </div>
//                     <div className="header-line">&nbsp;</div>
//                     <div className="form-items testimony text-center">
//                         <p>
//                             "Tracking and improving my credit score was simple and gave me the ability to negotiate a better deal."
//                         </p>
//                         <span className="tetimony-name">Jacqueline, NSW</span>
//                     </div>
//               </div>
//              </div>

//              <div className="visible-xs visible-sm visible-md">

//                    <div className="registration-advantages-bullets-container">
//                        <ul className="list-unstyled greenTicks">
//                          <li><i className="glyphicon glyphicon-ok"></i>  Safe &amp; Secure</li>
//                          <li><i className="glyphicon glyphicon-ok"></i>  Won't affect your credit file</li>
//                          <li><i className="glyphicon glyphicon-ok"></i>  Fast and totally free!</li>
//                          {/* <li><i className="glyphicon glyphicon-ok"></i>  {textVariants[pages.REGISTER + textVariants.textVariantSeparator + "header_create_free_account_3"]}</li> */}
//                        </ul>
//                    </div>

//              </div>
//      </div>
// );

// }


class RegistrationFormComplete extends React.Component {

  constructor(props, context){
      super(props, context);
      this.state = {
        showMiddleName: true
      };
    }

    

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState){
  }

  showMiddleName() {
    this.setState({showMiddleName : !this.state.showMiddleName});
  }

  getPersonalStep() {
    let {customer, onSave, onChange, saving, errors, onServiceError, textVariants, diplaySavedMessage, onCancel, omitFields, saveText, isOnlyForm} = this.props;
    return (
      <>
      <form method="POST">
      <div className="row">
        <div className="col-12 order-1">
            <TextInput
              name="email"
              id="email"
              placeholder={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "email_plh"]}
              value={customer.email}
              label={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "email_label"]}
              onChange={onChange}
              error={errors.email}
              toolTip={textVariants[pages.REGISTER+ textVariants.textVariantSeparator + "email_tooltip"]}
              shouldRender={!omitFields || !omitFields["email"]} 
              type="email"
              groupClass="form-label-group"/>
        </div>
        <div className="col-lg-6 col-12 small-input order-2">
            <TextInput
              name="firstName"
              id="firstName"
              placeholder={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "first_name_plh"]}
              value={customer.firstName}
              label={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "first_name_label"]}
              onChange={onChange}
              error={errors.firstName}
              groupClass="form-label-group"
              shouldRender={!omitFields || !omitFields["firstName"]} />
        </div>
        <div className={"col-lg-6 col-12 small-input right-text order-3" + (this.state.showMiddleName ? '' : ' d-none')}>
            <TextInput
              name="middleName"
              id="middleName"
              placeholder={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "middle_name_plh"]}
              value={ this.state.showMiddleName ? customer.middleName : ''}
              label={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "middle_name_label"]}
              onChange={onChange}
              error={errors.middleName}
              shouldRender={!omitFields || !omitFields["middleName"]}
              groupClass="form-label-group" />
        </div>
        {/* <div className="col-lg-6 offset-lg-6 col-12 small-input checkbox-field order-5">
          <div className="form-label-group form-checkbox ml">
            <input className="form-check-input" type="checkbox" value="" id="middle-heckCheck4" onChange={()=>{this.showMiddleName()}}/>
            <label className="form-check-label" htmlFor="middle-heckCheck4">
              I do not have a middle name
            </label>
          </div>
        </div> */}
        {/* < className="col-lg-6 col-12 small-input order-5"> */}
        <div className="col-12 order-5">
            <TextInput
              name="lastName"
              id="lastName"
              placeholder={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "last_name_plh"]}
              value={customer.lastName}
              label={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "last_name_label"]}
              onChange={onChange}
              error={errors.lastName} 
              shouldRender={!omitFields || !omitFields["lastName"]}
              groupClass="form-label-group"/>    
        </div>       

        <div className={"col-12 small-input order-6 " + (!this.props.isOnlyForm ? "col-lg-6": "col-lg-12")}>
            <DateInputGeneral
              customer={customer}
              error={errors.dob}
              textVariants={textVariants}
              onChange={onChange} 
              groupClass="form-label-group"
              shouldRender={!omitFields || !omitFields["dob"]}
              />
        </div>

        <div className={"col-12 small-input order-7 " + (!this.props.isOnlyForm ? "col-lg-6": "col-lg-12")}>
          <GenderInput
            name="gender"
            id="gender"
            value={customer.gender}
            label={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "gender_label"]}
            onChange={onChange}
            error={errors.gender}
            shouldRender={!omitFields || !omitFields["gender"]}
            cssClass={"radio-button mt-1 " + (!this.props.isOnlyForm ? "ml-lg-4": "")}
            />
        </div>

        {(!omitFields || !omitFields["address"]) &&
        <AddressInput
          addressname="address"
          hasLivedLessThan3YearsId="hasLivedLessThan3Years"
          prevAddressName="addressPrevious"
          prevAddress={customer.addressPrevious}
          address={customer.address}
          hasLivedLessThan3Years={customer.hasLivedLessThan3Years}
          label={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "address_label"]}
          labelPreviousAddress={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "prev_address_label"]}
          hasLivedLessThan3YearsLabel={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "use_prev_address_label"]}
          placeholder={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "address_plh"]}
          prevaddplaceholder={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "prev_address_plh"]}
          onChange={onChange}
          onServiceError={onServiceError}
          addressError={errors.address}
          prevAddressError={errors.addressPrevious}
          toolTip={textVariants[pages.REGISTER+ textVariants.textVariantSeparator + "address_tooltip"]}
          orderClass ={8}          
          />    
          }
          {(!omitFields || !omitFields["mobile"]) &&
            <div className="col-12 small-input order-7 col-lg-12">
                <TextNumericInput
                    name="mobile"
                    id="mobile"
                    placeholder="Mobile"
                    value={customer.mobile}
                    label={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "mobile_label"]}
                    onChange={onChange}
                    error={errors.mobile}
                    maxLength={10}
                    shouldRender={!omitFields || !omitFields["mobile"]}
                    groupClass="form-label-group" />
            </div>
           }

        {/*  <div className="col-lg-6 col-12 small-input right-text order-12"> */}
        <div className="col-lg-12 col-12 right-text order-10">
            <PasswordInput
              name="password"
              id="password"
              placeholder={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "password_plh"]}
              value={customer.password}
              label={textVariants[pages.REGISTER + textVariants.textVariantSeparator + "password_label"]}
              onChange={onChange}
              toolTip={textVariants[pages.REGISTER+ textVariants.textVariantSeparator + "password_tooltip"]}
              shouldRender={!omitFields || !omitFields["email"]}
              error={errors.password} 
              groupClass="form-label-group"/>
        </div>

        {(!omitFields || !omitFields["consent"]) &&
            <div className="col-lg-12 col-12 checkbox-field order-11">
              <div className="form-label-group form-checkbox ">
                <span className="reg-title-up reg-title-lbl">Required*</span>
                <input
                className="form-check-input"
                type="checkbox"            
                checked={customer.consent}            
                name="consent"
                id="consent"
                onChange={onChange}
                />
                <label className="form-check-label" htmlFor="consent">
                I accept I have read the GetCreditScore <a className="reg-link" target="_blank" href={RoutesVariables.RouteExternalTermsOfUse}>Terms of Use</a> &amp; <a className="reg-link" target="_blank" href={RoutesVariables.RouteExternalPrivacyPolicy}>Privacy Policy</a>; and agree that I may be contacted by GetCreditScore for marketing purposes. I understand I can unsubscribe at any time by updating my preferences.</label>
                {errors && errors.consent && (
                <>
                  <img src={ImagesPath.ImgIconAlert} className="img-fluid alert-icon n-alert-left" alt=""></img>              
                  <div className="invalid-feedback text-danger form-error-msg">
                          {errors.consent}
                  </div>
                </>
              )}
              </div>
            </div>
        }

        {(!omitFields || !omitFields["eqfxmarketing"]) &&
          <div className="col-lg-6 col-12 small-input checkbox-field order-12">
            <div className="form-label-group form-checkbox reg-title-small">
            <span className="reg-title-up reg-title-lbl">Additional marketing consent (optional)</span>
              <input
              className="form-check-input"
              type="checkbox"
              name="eqfxmarketing"
              checked={customer.eqfxmarketing}
              id="eqfxmarketing"
              onChange={onChange}
              />
              <label className="form-check-label reg-title-small" htmlFor="eqfxmarketing">
              I agree that I may also be contacted by Equifax and its partners for marketing purposes. I understand I can unsubscribe at any time by updating my preferences.	
              </label>
              {/* <Link className="reg-link" to={RoutesVariables.RouteMyPreferences}>my preferences</Link> */}
              {errors && errors.eqfxmarketing && (
              <>
                <img src={ImagesPath.ImgIconAlert} className="img-fluid alert-icon" alt=""></img>
                <div className=" invalid-feedback text-danger form-error-msg">
                        {errors.eqfxmarketing}
                </div>
              </>
            )}
            </div>
          </div>
        }

                
        {/* <div className="col-lg-6 offset-lg-6 col-12 order-13"></div> */}
        {!this.props.isOnlyForm && (
        <div className="col-lg-6 col-12 order-13">
          <span className="required">* Required Field</span>
          <button type="button" onClick={onSave} className={"btn btn-primary"+ (saving ? ' disabled': '')}>Register</button>
        </div>)}

        
      </div>
      {errors && errors["global"] && <ErrorLine message={errors["global"]} /> }
    </form>
   
    </>
    )
  }


render(){

    let {customer, onSave, onChange, saving, errors, onServiceError, textVariants, diplaySavedMessage, onCancel, omitFields, saveText, isOnlyForm} = this.props;

    return (
        <>
          { isOnlyForm && (
            <> {this.getPersonalStep()} </>
          )
          }
          { !isOnlyForm && (
              <section className={'section-form-log page-register'}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-10 offset-md-1 form-log-wrapper">
                       <div className="nav-register n-nav-register-1"> {/*nav-register-2 */}
                         {/* <a href="" className="prev-form"><img src={ImagesPath.ImgIconPrev} className="img-fluid" alt="" /></a> */}
                        <h2><span>1 <span className="separator" /> 6</span> Registration</h2>
                      </div>
                      <div className="form-log-box form-log-box-n">
                        {/* <img src={ImagesPath.ImgLogo} className="img-fluid logo" alt=""/> */}
                        <p>Get unlimited access to your credit score,<br/> dashboard, tools, insights and personalised offers.</p>
                        {this.getPersonalStep()}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )
          }
        </>
          );
        }
 }


 RegistrationFormComplete.propTypes = {
  customer: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onServiceError: PropTypes.func,
  textVariants: PropTypes.object.isRequired,
  saving: PropTypes.bool,
  errors: PropTypes.object,
  diplaySavedMessage: PropTypes.bool,
  onCancel: PropTypes.func,
  omitFields: PropTypes.object,
  saveText: PropTypes.string.isRequired
};



class RegistrationForm extends React.Component {

  constructor(props, context) {
      super(props, context);

      this.state = {
          onSaveClicked: false
      };

      this._onSaveCustom = this._onSaveCustom.bind(this);
  }

  _onSaveCustom(event){
    event.preventDefault();
    this.setState({onSaveClicked: true}, () => this.props.onSave(event) );
  }

  componentDidMount(){
    initTonikForms();
  }

  componentWillReceiveProps(nextProps){
    if(this.state.onSaveClicked && nextProps && nextProps.errors && nextProps.errors.hasError){
        // Scroll into the error
        this.setState({onSaveClicked: false}, () => funcs.scrollIntoView(nextProps.errors.firstOne) );
    }
  }


    render() {


    let updatedProps = Object.assign({}, this.props, {onSave: this._onSaveCustom} );

    return (<RegistrationFormComplete  {...updatedProps} />);
   
      // if(this.props.isOnlyForm) {
    //  return (<RegistrationFormPlain  {...updatedProps} />);
    // }
    // else {
    //   return (
    //     <RegistrationFormComplete  {...updatedProps} />
    //   );
    // }
    //   }
    }
}

  RegistrationForm.propTypes = {
      customer: PropTypes.object.isRequired,
      onSave: PropTypes.func.isRequired,
      onChange: PropTypes.func.isRequired,
      onServiceError: PropTypes.func,
      textVariants: PropTypes.object.isRequired,
      saving: PropTypes.bool,
      errors: PropTypes.object,
      diplaySavedMessage: PropTypes.bool,
      onCancel: PropTypes.func,
      omitFields: PropTypes.object,
      saveText: PropTypes.string.isRequired,
      isOnlyForm: PropTypes.bool
  };

export default RegistrationForm;
