import React from 'react';
import {useRef} from 'react';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Menu from  '../menu/Menu.js';
import * as pages from '../shared/pages';

import * as layoutActions from '../../redux/actions/layoutActions';
import * as userActions from '../../redux/actions/userActions';
import * as scoreActions from '../../redux/actions/scoreActions';

import MyDetailsTitleSection from './MyDetailsTitleSection.js';

import RegistrationForm from '../registration/RegistrationForm';
import ValidationApi from '../../api/validationApi.js';
import HelperApi from '../../api/HelperApi.js';

import LoadingIndicator from '../common/controls/LoadingIndicator';
import {ApiFailedToLoadComponentWithText} from '../common/ApiFailedToLoadComponent';
import CurrentAddressForm from '../mydetails/CurrentAddressForm';
import TypeIdForm from '../mydetails/TypeIdForm';

import * as RoutesVariables from '../../config/routesVariables';
import {USERROLES, hasOneRole} from '../../common/gcsLogic';

import {initTonikForms} from '../../tonik/shared/forms';
import * as ImagesPath from '../../config/imagesPath';
import { Link } from 'react-router-dom';
import {parse} from 'query-string';
import ChangePasswordForm from '../../components/login/ChangePasswordForm';
import FooterSmallSection from '../common/footerSmall';

class MyDetailsPage extends React.Component { 
    constructor(props, context) {
        super(props, context);
        
        const params = parse(props.location.search);
        this.state = {
            customer:  {  dob: { dd: '', mm: '', yy: '' } , address:'', addressPrevious: '', hasLivedLessThan3Years: false , mobile: ''},
            errors: {},
            //hashAccountCreation: null,
            saving: false,
            diplaySavedMessage: false,
            isServerError: false,
            omitFields: {              
              email: true,              
              password: true,
              eqfxmarketing: true,
              consent: true,
              address: true,
            },
            tabIndex: params['step'] ? params['step'] : 1,
            isMobile: false
        };
        this.onUpdateCustomerDetails = this.onUpdateCustomerDetails.bind(this);
        this.updateCustomerState = this.updateCustomerState.bind(this);
        this.updateCustomerServiceAddressError = this.updateCustomerServiceAddressError.bind(this);
        this.saveCustomerFromState = this.saveCustomerFromState.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount() {
      
      //window['initAppJs'](true);
      
      this.props.actions.getTextVariants();

      this.props.actions.isAuthenticated().then(e =>
      {
              if(!hasOneRole(e.roles, USERROLES.TwoFactor)){
                this.props.history.push(RoutesVariables.RouteLogin + '?retUrl=' + RoutesVariables.MyDetails);
              }
              this.props.actions.getMenuForPage(pages.MYDETAILS);
              //this.props.actions.loadScoreProfile({includeScore: true, includeAdvices: true, includeHistory:  true})
              this.props.actions.loadUserDetails(true).catch(e =>
              {
                 if (e.status == 500) {
                    this.setState({ isServerError: true });
                 }
              });
      });

      initTonikForms();

      if(this.state.tabIndex != 1) {
        var lis = document.getElementById('tabs').children;
        for(var i =0; i< lis.length; i++){
          lis[i].className = '';
        }
        lis[this.state.tabIndex-1].className = 'current';
      }

      this.typeIdRef = React.createRef();

    }

    componentWillReceiveProps(nextProps) {

        if (!nextProps.isAuthenticated) {
            // go back to login
            this.props.history.push(RoutesVariables.RouteLogin + '?retUrl=' +  RoutesVariables.MyDetails);
        }
        else {
          if(nextProps.userProfile.userDetails) {
              let newCustomer = Object.assign({},  nextProps.userProfile.userDetails);
              this.setOptionsNames(newCustomer);
              if(newCustomer.gender != null ){
                newCustomer.gender = newCustomer.gender ? 'M':'F'
              } else {newCustomer.gender = 'O'}
              this.setState({customer: newCustomer});
          }
        }
      }

      changeTab(e, index, isMobile) {
        e.preventDefault();
        e.stopPropagation();
        var lis = e.currentTarget.parentNode.children;
        for(var i =0; i< lis.length; i++){
          lis[i].className = '';
        }
        e.currentTarget.className = 'current'
        this.setState({tabIndex: index, isMobile: isMobile})
      }

      onChangePassword(evt){
        this.setState({ saving: true });

        evt.preventDefault();

        if (!this.changePasswordIsValid()) {
            this.setState({ saving: false });
            return;
        }

        let errors = {};

        let oldPass = this.state.customer.oldPassword;
        if(this.state.customer["hasPassword"]){
          oldPass = this.state.p;
        }

        //this.props.actions.
        this.props.actions.changePassword(oldPass, this.state.customer.newPassword).then(
          r => {
            //this.setState({ saving: false, currentPage: 'changePasswordSucceed' });
            this.props.history.push(RoutesVariables.RouteCreditScore);
        }).catch(e => {
            if(e.status == 401){
                errors.generic = this.props.textVariants[pages.CHANGEPASSWORD + this.props.textVariants.textVariantSeparator + "error_401"];
            }
            else{
                errors.generic = this.props.textVariants["Api_Generic"];
            }

            this.setState({saving: false, errors: errors});
        });

        return false;
    }

    changePasswordIsValid() {

      let formIsValid = true;
      let errors = {};

      //check if old password match value from the input box=password?

      var errorPassword = ValidationApi.validatePassword(this.state.customer.newPassword);
      if (errorPassword) {
          errors.newPassword = errorPassword;
          formIsValid = false;
      }

      var errorResetPassword = ValidationApi.validatePassword(this.state.customer.confirmPassword);
      if (errorResetPassword) {
          errors.confirmPassword = errorResetPassword;
          formIsValid = false;
      }

      if(!this.state.customer["hasPassword"]){
              if (!this.state.customer.oldPassword) {
                errors.oldPassword = this.props.textVariants[pages.CHANGEPASSWORD + this.props.textVariants.textVariantSeparator + "error_oldPasswordRequired"];
                formIsValid = false;
              }
      }

      if (this.state.customer.newPassword != this.state.customer.confirmPassword) {
        errors.generic = this.props.textVariants[pages.CHANGEPASSWORD + this.props.textVariants.textVariantSeparator + "error_passwordMismatch"];
        formIsValid = false;
      }

      this.setState({errors: errors});

      return formIsValid;
    }

    onOkPasswordChanged(evt) {
      this.props.history.push(RoutesVariables.RouteCreditScore);
    }

    setOptionsNames(user) {
      let namesOptions = [];
      if(user) {
        let fullName = user['firstName'] + ' ' + user['lastName'];
        namesOptions.push(fullName);
        if(user['middleName']) {
          fullName = user['firstName'] + ' ' +  user['middleName'][0] + ' ' + user['lastName'];
          namesOptions.push(fullName)
        }
      }
      this.setState({namesOptions: namesOptions})
    }

    onUpdateCustomerDetails(event) {
      event.preventDefault();      
        let validationResult = ValidationApi.validateCustomer(this.state.customer, this.state.omitFields);
       
      if (validationResult.isValid) {
          this.setState({saving : true, diplaySavedMessage: false});

          this.saveCustomerFromState(this.state.customer).then(r => {
            this.setState({ saving: false, diplaySavedMessage: true });
                        
            if(r && r.isPIIDataUpdated){

              // re-authenticate
              return this.props.actions.refreshAuthentication().then(r =>{              
                var navigateUrl = r.redirect_uri || RoutesVariables.RouteDashboard;                     
                this.props.history.push(navigateUrl);
              });
              
            }
            else{
              this.props.history.push(RoutesVariables.RouteCreditScore);
            }
          }).catch(e => {
            let errors = this.state.errors;
            let msg =  null;

            if (e.status == 400) {
              msg = this.props.textVariants[pages.MYDETAILS + this.props.textVariants.textVariantSeparator + "400_errorOnUpdate"];

            } else if(e.status == 404){
              msg = this.props.textVariants[pages.MYDETAILS + this.props.textVariants.textVariantSeparator + "404_userNotFound"];

            } else if (e.status == 500){
              msg = this.props.textVariants["Api_Generic"];

            }

            if(!msg){
              msg = e.message
            }

            errors["global"] = msg;
            this.setState({errors: errors, saving : false, diplaySavedMessage: false});
          });
      } else {
        let newCustomer = this.state.customer;
        newCustomer.isCurrentEmailValid = validationResult.isCurrentEmailValid;
        newCustomer.isCurrentMobileValid = validationResult.isCurrentMobileValid;

        this.setState({customer: newCustomer, errors: validationResult.errors, saving: false});
      }      
    }
    saveCustomerFromState(customer, recaptchaToken){
      var updatingCustomer = Object.assign({}, customer);
      
      HelperApi.setInnerDateOfBirthAndDobFromNumbers(updatingCustomer, customer.dob.yy, customer.dob.mm, customer.dob.dd);
      
      updatingCustomer.gender = null;
      if (customer.gender && customer.gender != 'O'){
        updatingCustomer.gender = customer.gender == 'M';
      }        
      
      return this.props.actions.updateUserDetails(updatingCustomer);
    }

    updateCustomerState(event) {

        const field = event.target.name;
        let customer = this.state.customer;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        customer[field] = value;

        let errors = this.state.errors;
        errors[field] = field == 'dob' ? {} : '';

        if(field == 'email'){
        customer.isCurrentEmailValid = false;
        }

        if(field == 'mobile'){
        customer.isCurrentMobileValid = false;
        }    

        return this.setState({ customer: customer, errors:errors });
    }

  updateCustomerServiceAddressError(event){

    const field = event.target.name;
    let errors = this.state.errors;
    if (event.target.status && event.target.status == 401) {
      //console.log("external service error code="+event.target.status);
    }
    errors[field] = this.props.textVariants["Error_cant_find_address"];

    return this.setState({ errors:errors });
  }

    onCancel(event){
      event.preventDefault();
      //const browserHistory = createBrowserHistory();
      this.props.hisotry.goBack();
    }

    render() {
      let pageContent = null;

      if (this.state.isServerError) {
        pageContent = <ApiFailedToLoadComponentWithText text={this.props.textVariants["Api_RetrievingProfile_Error"]} />
      }
      else if(!this.props.userProfile.userDetails){
        pageContent =
                <LoadingIndicator
                  textPosition='bottom'
                  text={this.props.textVariants[pages.MYDETAILS + this.props.textVariants.textVariantSeparator + "loading_text"]}
                  size='200' />;
      }
      else {
        pageContent = <>

              { this.state.tabIndex && this.state.tabIndex == 1 && <RegistrationForm
                onSave={this.onUpdateCustomerDetails}
                onChange={this.updateCustomerState}
                errors={this.state.errors}
                saving={this.state.saving}
                customer={this.state.customer}
                onServiceError={this.updateCustomerServiceAddressError}
                textVariants={this.props.textVariants}
                diplaySavedMessage={this.state.diplaySavedMessage}
                onCancel={this.onCancel}
                omitFields={this.state.omitFields}
                saveText={this.props.textVariants[pages.REGISTER + this.props.textVariants.textVariantSeparator + "save_btn"]}
                isOnlyForm={true}
              />}

              { this.state.tabIndex && this.state.tabIndex == 2 && <CurrentAddressForm
                onSave={this.onUpdateCustomerDetails}
                onChange={this.updateCustomerState}
                errors={this.state.errors}
                saving={this.state.saving}
                customer={this.state.customer}
                onServiceError={this.updateCustomerServiceAddressError}
                textVariants={this.props.textVariants}
                diplaySavedMessage={this.state.diplaySavedMessage}
                onCancel={this.onCancel}
                omitFields={this.state.omitFields}
                saveText={this.props.textVariants[pages.REGISTER + this.props.textVariants.textVariantSeparator + "save_btn"]}
              />}

              {/* { this.state.tabIndex && this.state.tabIndex == 3 && <TypeIdForm
                onSave={this.onUpdateCustomerDetails}
                onChange={this.updateCustomerState}
                errors={this.state.errors}
                saving={this.state.saving}
                customer={this.state.customer}
                onServiceError={this.updateCustomerServiceAddressError}
                textVariants={this.props.textVariants}
                diplaySavedMessage={this.state.diplaySavedMessage}
                onCancel={this.onCancel}
                omitFields={this.state.omitFields}
                namesOptions={this.state.namesOptions}
                saveText={this.props.textVariants[pages.REGISTER + this.props.textVariants.textVariantSeparator + "save_btn"]}
                ref={this.typeIdRef}
              />}

              { this.state.tabIndex && this.state.tabIndex == 4 && <ChangePasswordBox
                textVariants={this.props.textVariants}
                onChange={this.updateCustomerState}
                error={this.state.errors}
                customer={this.state.customer}
                onChangePassword={this.onChangePassword}
                saving={this.state.saving}
                isOnlyForm={true}
                />} */}
              
              <hr/>
              {/* <a href={RoutesVariables.DeleteAccount}>Delete my account</a> */}
            </>;
      }

        return (
          <span>
            <span id="dashboard-menu"></span>
          <div className="page-bg page-account">
             <Menu menuItemList={this.props.menuItemList}  user={this.props.userProfile} renderMobile={true}/>

             <section className="section-recent">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h1>Account</h1>
                  </div>
                </div>
              </div>
              </section>
                
          </div>
          <section className="page-recent-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 offset-lg-1 col-md-4 column-nav d-none d-md-block">
                <aside className="nav-aside">
                  <ul id="tabs" ref={this.tabs}>
                    <li className="current" onClick={(e)=>this.changeTab(e, 1, false)}><a href="#">Your details</a></li>
                    <li onClick={(e)=>this.changeTab(e, 2, false)}><a href="#">Current address</a></li>
                    {/* <li onClick={(e)=>this.changeTab(e, 3, false)}><a href="#">Identity documents</a></li>
                    <li onClick={(e)=>this.changeTab(e, 4, false)}><a href="#">Set your new password</a></li> */}
                    {this.state.tabIndex && (this.state.tabIndex == 1 || this.state.tabIndex == 2) &&(<li>
                        <button onClick={(e)=>{this.onUpdateCustomerDetails(e)}} type="button" className={"btn btn-primary full-width" + (this.state.saving ? ' disabled' : '') }>Save changes
                          {this.state.saving &&(<div className="spinner-border text-light float-right mt-2" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>)}
                        </button>
                    </li>)}
                    {this.state.tabIndex && this.state.tabIndex == 4 &&(<li>
                      <button onClick={(e)=>{this.onChangePassword(e)}} type="button" className={"btn btn-primary full-width" + (this.state.saving ? ' disabled' : '') }>Change Password
                        {this.state.saving &&(<div className="spinner-border text-light float-right mt-2" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>)}
                      </button>
                    </li>)}
                    {this.state.tabIndex && this.state.tabIndex == 3 &&(<li>
                      <button onClick={(e)=>{this.typeIdRef.current.saveCustomerIds()}} type="button" className={"btn btn-primary full-width" + (this.state.saving ? ' disabled' : '') }>Change IdType
                        {this.state.saving &&(<div className="spinner-border text-light float-right mt-2" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>)}
                      </button>
                    </li>)}
                  </ul>
                </aside>
              </div>

              {this.state.isMobile == false &&(<div className='col-lg-6 col-md-8 col-12 box-form d-none d-md-block mb-2'>
                {pageContent}
              </div>)}


<div className="col-12 d-md-none d-sm-block">
  <div id="accordion">
    <div className="card">
      <div className="card-header" id="headingOne">
        <h5 className="mb-0">
          <button onClick={(e)=>this.changeTab(e, 1, true)} className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            Your details
          </button>
        </h5>
      </div>

      <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
        <div className="card-body ml-2 mr-2">
          { this.state.tabIndex && this.state.tabIndex == 1 && this.state.isMobile && (pageContent)}
        </div>
      </div>
    </div>

    <div className="card">
      <div className="card-header" id="headingTwo">
        <h5 className="mb-0">
          <button onClick={(e)=>this.changeTab(e, 2, true)} className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
            Current address
          </button>
        </h5>
      </div>

      <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
        <div className="card-body ml-2 mr-2">
          { this.state.tabIndex && this.state.tabIndex == 2 && this.state.isMobile && (pageContent)}
        </div>
      </div>
    </div>
    {/* <div className="card">
      <div className="card-header" id="headingThree">
        <h5 className="mb-0">
          <button onClick={(e)=>this.changeTab(e, 3, true)} className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">   
            Identity documents
          </button>
        </h5>
      </div>

      <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
        <div className="card-body ml-2 mr-2">
        { this.state.tabIndex && this.state.tabIndex == 3 && this.state.isMobile &&  (pageContent)}
        </div>
      </div>
    </div> */}
    {/* <div className="card">
      <div className="card-header" id="headingFour">
        <h5 className="mb-0">
          <button onClick={(e)=>this.changeTab(e, 4, true)} className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
            Set your new password
          </button>
        </h5>
      </div>

      <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
        <div className="card-body ml-2 mr-2">
          { this.state.tabIndex && this.state.tabIndex == 4 && this.state.isMobile && (pageContent)}
        </div>
      </div>
    </div> */}
  </div>

  <div className="col-12 button-fixed">
  {this.state.tabIndex && (this.state.tabIndex == 1 || this.state.tabIndex == 2) &&(<a onClick={(e)=>{this.onUpdateCustomerDetails(e)}} className={"btn btn-primary full-width" + (this.state.saving ? ' disabled' : '') }> Save changes
  {this.state.saving &&(<div className="spinner-border text-light float-right mt-2" role="status">
                  <span className="sr-only">Loading...</span>
                </div>)}
  </a>)}
  {this.state.tabIndex && this.state.tabIndex == 4 &&(<a onClick={(e)=>{this.onChangePassword(e)}} className={"btn btn-primary full-width" + (this.state.saving ? ' disabled' : '') }> Change Password
  {this.state.saving &&(<div className="spinner-border text-light float-right mt-2" role="status">
                  <span className="sr-only">Loading...</span>
                </div>)}
  </a>)}


  {this.state.tabIndex && this.state.tabIndex == 3 &&(<a onClick={(e)=>{this.typeIdRef.current.saveCustomerIds()}} className={"btn btn-primary full-width" + (this.state.saving ? ' disabled' : '') }> Change IdType
  {this.state.saving &&(<div className="spinner-border text-light float-right mt-2" role="status">
                  <span className="sr-only">Loading...</span>
                </div>)}
  </a>)}
  </div>
</div>

            </div>
          </div>
          </section>
          <FooterSmallSection />
          </span>
        );
    }  
}

MyDetailsPage.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {

  var menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.MYDETAILS]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.MYDETAILS];
     }
   }

   let txtVariants = {};
   if(state.textVariants){
     txtVariants = state.textVariants;
   }

  return {
    menuItemList: menuItems,
    textVariants: txtVariants,
    userProfile:  state.userProfile,
    isAuthenticated: state.userProfile.isAuthenticated
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, layoutActions, userActions, scoreActions) , dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyDetailsPage);
