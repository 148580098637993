/// <reference path="../../api/mockProductApi.js" />

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {parse} from 'query-string';

import * as pages from '../shared/pages';
import {HomeSplashSection, HomeSplashCallToActionPlaceholder, HomeSplashFeaturedOnSection} from './HomeSplashSection';

import RegistrationForm from '../registration/RegistrationForm';

import CounterSection from './../sections/CounterSection';

import * as productActions from '../../redux/actions/productActions';
import * as layoutActions from '../../redux/actions/layoutActions';
import * as homePageActions from '../../redux/actions/homePageActions';
import * as userActions from '../../redux/actions/userActions';
import * as trackingActions from '../../redux/actions/trackingActions';
import * as RoutesVariables from '../../config/routesVariables';


import ValidationApi from '../../api/validationApi.js';
import HelperApi from '../../api/HelperApi';

//
// import ProductApi from '../../api/mockProductApi.js'
// import LayoutApi from '../../api/mockLayoutApi.js'
// import ArticlesApi from '../../api/mockArticlesApi.js'


export class HomeOhNoPage  extends React.Component{
    constructor(props, context){
        super(props, context);

        this.state = {
            customer:  {  dob: { dd: '', mm: '', yy: '' } , address:'', addressPrevious: '', hasLivedLessThan3Years:false },
            errors: {dob: { dd: '', mm: '', yy: '' }},
            //hashAccountCreation: null,
            saving: false,
             omitFields: {
                mobile: true
            }
        };

       this.onGetScoreClick = this.onGetScoreClick.bind(this);
       this.redirect = this.redirect.bind(this);
       this.onLearnMoreClick = this.onLearnMoreClick.bind(this);
       this.onProductClick = this.onProductClick.bind(this);
       this.onArticleClick = this.onArticleClick.bind(this);
       this.onResourceClick = this.onResourceClick.bind(this);

       this.onLoginClick = this.onLoginClick.bind(this);

       this.onSaveCustomer = this.onSaveCustomer.bind(this);
       this.updateCustomerState = this.updateCustomerState.bind(this);
       this.updateCustomerServiceAddressError = this.updateCustomerServiceAddressError.bind(this);
       this.saveCustomerFromState = this.saveCustomerFromState.bind(this);
       this.onRegistrationComplete = this.onRegistrationComplete.bind(this);
    }


    componentWillMount(){

    }

    componentWillReceiveProps(nextProps){

    }

    componentDidMount() {

      this.props.actions.getMenuForPage(pages.HOMEOHNO);
      this.props.actions.loadProducts();
      this.props.actions.loadkeyFeatureList();
      this.props.actions.loadProcessSteps();
      this.props.actions.loadArticlesForHomePage();
      this.props.actions.getTextVariants();
      this.props.actions.initUser();


        let tid = parse(this.props.location.search).transaction_id;

        if(tid){
          this.props.actions.trackReferralFromPartner(tid, null);
        }
    }

    componentDidUpdate(prevProps, prevState){

      if(prevProps && this.props && prevProps.processSteps && this.props.processSteps && (prevProps.processSteps.length != this.props.processSteps.length)){
      }
    }



    onLoginClick(event){
         this.props.actions.trackButtonClick(pages.HOMEOHNO + '-oh-no', 'login_splash');
         this.redirect(RoutesVariables.RouteLogin);
    }

    redirect(url) {
        this.props.history.push(url);
      }

    onGetScoreClick(event){

    //check the event properties to send tracking event

       if(this.props.isAuthenticated){
         this.redirect(RoutesVariables.RouteCreditScore);
       }
       else{
         this.props.actions.trackGetScoreButtonClick(event.target.id);
         //this.redirect('/test#registration-section');
       }
    }

    onLearnMoreClick(event){
       //this.redirect('/dashboard#dashboard-top-page');       
    }

    onProductClick(event, product){

      this.props.actions.trackGcsProductClick(product, pages.HOMEOHNO, "oh-no-page");
    }

    onArticleClick(event, article){

        //event.preventDefault();
        //this.context.router.push('/article1/' + article.seoName);
    }

    onResourceClick(event, resourceId){

    }

    onRegistrationComplete(){
      this.props.history.push('/');
      return false;
    }

    onSaveCustomer(event) {
      event.preventDefault();
      const that = this;
      this.setState({saving: true});

        let validationResult = ValidationApi.validateCustomer(this.state.customer, this.state.omitFields);

      if (validationResult.isValid) {
        this.saveCustomerFromState(this.state.customer).then(r => {
          let jackMediaAppend = "";
          let tid = parse(this.props.location.search).transaction_id;

          if(tid){
            jackMediaAppend = "?tid=" + tid;
          }

          this.props.history.push(RoutesVariables.RouteCheckYourInbox + (jackMediaAppend ? jackMediaAppend : ""));
        }).catch(e => {
          let errors = this.state.errors;
          let msg =  null;
          if(e.status == 409){
              msg = this.props.textVariants[pages.REGISTER + this.props.textVariants.textVariantSeparator + "409_startAgain"];
          }
          else if(e.status == 302){
            msg = this.props.textVariants[pages.REGISTER + this.props.textVariants.textVariantSeparator + "302_userExists"];
          }
          else if(e.status == 400){
            e.json().then(function(response) {
              response.forEach(error => {
                errors[error.field] = error.message;
              });

              that.setState({errors: errors, saving : false});
            });

            msg = this.props.textVariants[pages.REGISTER + this.props.textVariants.textVariantSeparator + "400_badRequest"];
          }
          else if(e.status == 500){
            msg = this.props.textVariants["Api_Generic"];
          }

          errors["global"] = msg;
          this.setState({errors: errors, saving: false});
        });

      } else {
        let newCustomer = this.state.customer;
        newCustomer.isCurrentEmailValid = validationResult.isCurrentEmailValid;
        newCustomer.isCurrentMobileValid = validationResult.isCurrentMobileValid;

        this.setState({customer: newCustomer, errors: validationResult.errors, saving: false});
      }
    }

    saveCustomerFromState(customer){
      var registeredCustomer = Object.assign({}, customer);
      //dob: { 'ddofbirth': '', 'mmofbirth': '', 'yyyyofbirth': '' }
      HelperApi.setInnerDateOfBirthAndDobFromNumbers(registeredCustomer, customer.dob.yy, customer.dob.mm, customer.dob.dd);

      //registeredCustomer.dateOfBirth.setTime( registeredCustomer.dateOfBirth.getTime() + registeredCustomer.dateOfBirth.getTimezoneOffset()*60*1000 );
      //registeredCustomer.email = this.props.userProfile.username; // || this.props.userProfile.rememberMeInfo.username;
      registeredCustomer.gender = customer.gender;

      return this.props.actions.registerFull(registeredCustomer);
    }

    updateCustomerState(event) {

        const field = event.target.name;

        let customer = this.state.customer;
        customer[field] = event.target.value;

        let errors = this.state.errors;
        errors[field] = field== 'dob' ? {} : '';

        return this.setState({ customer: customer, errors:errors });
    }

    updateCustomerServiceAddressError(event){

      const field = event.target.name;

      let errors = this.state.errors;

      if (event.target.status && event.target.status == 401) {
        console.log("external service error code="+event.target.status);
      }

      errors[field] = this.props.textVariants["Error_cant_find_address"];

      return this.setState({ errors:errors });
    }

    render(){

        //let menuComponent = (<Menu menuItemList={this.props.menuItemList}  />)

        return (

            <div className="homePage">

              <HomeSplashSection
                cssClass="homesplash-old-style"
                textVariants={this.props.textVariants}
                menuItemList={this.props.menuItemList}
              >
                <div>
                  <HomeSplashCallToActionPlaceholder
                        onGetScoreClick={this.onGetScoreClick}
                        onLoginClick={this.onLoginClick}
                        isAuthenticated={this.props.isAuthenticated}
                        textVariants={this.props.textVariants}
                        userProfile={this.props.userProfile}
                        originPage={pages.HOMEOHNO}
                        location={this.props.location}
                     />

                  <HomeSplashFeaturedOnSection  textVariants={this.props.textVariants} />

                  <CounterSection
                    key="scorecounter-transparent"
                    counterSectionTheme="counter-section-theme-transparent counter-section-theme-xsmall-transparent"
                    forMobile
                  />
                </div>
              </HomeSplashSection>

              <div className="registration" id="registration-section">

                  <RegistrationForm
                    onSave={this.onSaveCustomer}
                    onChange={this.updateCustomerState}
                    errors={this.state.errors}
                    saving={this.state.saving}
                    customer={this.state.customer}
                    onServiceError={this.updateCustomerServiceAddressError}
                    omitFields={this.state.omitFields}
                    textVariants={this.props.textVariants}
                    saveText={this.props.textVariants["Btn_GetMyScoreNow"]}
                   />                  
             </div>
            </div>

        )
        }
    };

HomeOhNoPage.contextTypes = {
  router: PropTypes.object
};


function mapStateToProps(state, ownProps) {

  var menuItems = [];
  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.HOMEOHNO]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.HOMEOHNO];
     }
   }

   var keyFeatureList = [];
   if(state.homePage && state.homePage.keyFeatureList){
     keyFeatureList = state.homePage.keyFeatureList;
   }

   var processSteps = {};
   if(state.homePage && state.homePage.processSteps){
     processSteps = state.homePage.processSteps;
   }

   var articles = [];
   if(state.homePage && state.homePage.articles){
     articles = state.homePage.articles;
   }

   let txtVariants = {};
   if(state.textVariants){
     txtVariants = state.textVariants;
   }


   let products = [];
   let productLoading = null;
   if(state.productResult && state.productResult.products){
     products = state.productResult.products;
     productLoading = state.productResult.loading;
   }

   let isAnonymous = true;
   if(state.productResult){
     isAnonymous = state.productResult.isAnonymous;
   }

  return {
    products : products,
    productLoading: productLoading,
    isAnonymous: isAnonymous,
    rememberMe: state.userProfile.rememberMeInfo.rememberMe,
    isAuthenticated: state.userProfile.isAuthenticated,
    menuItemList: menuItems,
    keyFeatureList: keyFeatureList,
    articles: articles,
    processSteps: processSteps,
    textVariants: txtVariants,
    userProfile: state.userProfile
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, productActions, layoutActions, homePageActions, userActions, trackingActions) , dispatch)
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(HomeOhNoPage);
