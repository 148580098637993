import React from 'react';
import PropTypes from 'prop-types';
import * as ImagesPath from '../../../config/imagesPath';

const TextNumericInput = ({ name, label, onChange, placeholder, value, error, autoComplete = "on", shouldRender = true, toolTip, maxLength = 100, required = false, groupClass = "form-group"}) => {

     let inputCls = 'form-control';

     //let groupClass = "form-group";
      if (error && error.length > 0) {
        inputCls += ' is-invalid';
      }

      let component =   <div ></div>;
      if(shouldRender){
        component =   <div className={groupClass}>

              <input
                type="tel"
                inputMode="numeric"
                step="1"
                pattern="\d*"
                data-parsley-pattern="[0-9,]*"
                maxLength={maxLength}
                name={name}
                id={name}
                className={inputCls}
                autoComplete={autoComplete}
                placeholder={placeholder}
                value={value}
                required={required}
                onChange={onChange}
               />

                <label htmlFor={name}>{label}</label>

                {error && (
                  <>
                    <img src={ImagesPath.ImgIconAlert} className="img-fluid alert-icon" alt=""></img>
                    <div className="invalid-feedback">
                            {error}
                    </div>
                  </>
                )}

               {toolTip && <div className="formTooltipContainer"><a className="tooltipInfo" data-placement="top" data-toggle="tooltip" data-title={toolTip["helpText"]}>
                    <i className="score-a glyphicon glyphicon-info-sign" ></i>
                    {toolTip["linkText"]}
               </a></div>}


          </div>;
      }

      return (component);
};

TextNumericInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  shouldRender: PropTypes.bool,
  toolTip: PropTypes.object,
  error: PropTypes.string
};

export default TextNumericInput;
