import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {parse} from 'query-string';

import * as pages from '../shared/pages';
import * as GlobalFixedVariables from '../../config/GlobalFixedVariables';

import {HomeSplashSection, HomeSplashCallToActionPlaceholder, HomeSplashFeaturedOnSection} from './HomeSplashSection';
import HomeKeyFeaturesSection from './HomeKeyFeaturesSection';
import ProductSection from './../sections/ProductSection';
import RegistrationForm from '../registration/RegistrationForm';
import SectionFaq from '../sections/SectionFaq';
import {staticSectionFAQS} from '../shared/staticFaq';
import TrustPilotSection from './../sections/TrustPilotSection';
import CounterSection from './../sections/CounterSection';

import * as productActions from '../../redux/actions/productActions';
import * as layoutActions from '../../redux/actions/layoutActions';
import * as homePageActions from '../../redux/actions/homePageActions';
import * as userActions from '../../redux/actions/userActions';
import * as trackingActions from '../../redux/actions/trackingActions';

import ValidationApi from '../../api/validationApi.js';
import HelperApi from '../../api/HelperApi.js';
import * as RoutesVariables from '../../config/routesVariables';

import {USERROLES, hasOneRole} from '../../common/gcsLogic';
import {tonikInitDashboard, tonikInitCarousel} from '../../tonik/dashboard';
import {tonikInitModal} from '../../tonik/modal';

const $ = window.$;

// import ProductApi from '../../api/mockProductApi.js'
// import LayoutApi from '../../api/mockLayoutApi.js'
// import ArticlesApi from '../../api/mockArticlesApi.js'

const NewMemberProgressBar = () => {
  return (
    <div className="new-member">
      <div className="new-member-loading">
        <div className="progress">
          <div className="progress-bar progress-bar-striped progress-bar-animated active new-member-progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" />
        </div>
      </div>
    </div>
  );
};

const NewMemberErrorSection = ({textVariants}) => {
  return (
    <div className="new-member left-container-padding right-container-padding">
      <div className="new-member-error col-sm-offset-2">
        <span className="text-danger">
          {textVariants[pages.REGISTER + textVariants.textVariantSeparator + "new_member_error"]}
        </span>
      </div>
    </div>
  );
};


export class HomeOldStylePage  extends React.Component{
    constructor(props, context){
        super(props, context);

        this.state = {
            customer:  {  dob: { dd: '', mm: '', yy: '' } , address:'', addressPrevious: '', hasLivedLessThan3Years:false, email: props.registeringUsername, firstName: '', middleName: '', lastName: '' },
            extendedCustomer: { ci: '', ch: '' },
            errors: {dob: { dd: '', mm: '', yy: '' }},
            //hashAccountCreation: null,
            saving: false,
            pageVariation: "",
            trustPilotSectionTheme: "trust-pilot-section-theme-white trust-pilot-section-theme-inline-minimal",
            formLoading: false,
            omitFields: {
                mobile: true
            }
        };

       this.onGetScoreClick = this.onGetScoreClick.bind(this);
       this.redirect = this.redirect.bind(this);
       this.onLearnMoreClick = this.onLearnMoreClick.bind(this);
       this.onProductClick = this.onProductClick.bind(this);
       this.onArticleClick = this.onArticleClick.bind(this);
       this.onResourceClick = this.onResourceClick.bind(this);

       this.onLoginClick = this.onLoginClick.bind(this);

       this.onSaveCustomer = this.onSaveCustomer.bind(this);
       this.updateCustomerState = this.updateCustomerState.bind(this);
       this.updateCustomerServiceAddressError = this.updateCustomerServiceAddressError.bind(this);
       this.saveCustomerFromState = this.saveCustomerFromState.bind(this);
       this.onRegistrationComplete = this.onRegistrationComplete.bind(this);
       this.getComponentList = this.getComponentList.bind(this);
    }


    componentWillMount(){

    }

    componentWillReceiveProps(nextProps){
      if (nextProps.registeringUsername != this.props.registeringUsername) {
        let customer = Object.assign({}, this.state.customer);
        customer.email = nextProps.registeringUsername;
        this.setState({ customer });
      }
    }

    componentDidMount() {

      let pageVariation = "";
      // if(pageVariation = this.props.route["pageVariation"]){
      if (pageVariation = this.props.pageVariation) {
        let partnerSourceId = null;
        let customer = Object.assign({}, this.state.customer);
        if(pageVariation == "toyota"){
          customer.partnerSourceId = GlobalFixedVariables.PARTNER_TOYOTA;
          this.props.actions.partnerStick(customer.partnerSourceId);
          this.setState({pageVariation: pageVariation, customer: customer});
        }
        else if(pageVariation == "powertorque"){
          customer.partnerSourceId = GlobalFixedVariables.PARTNER_POWERTORQUE;
          this.props.actions.partnerStick(customer.partnerSourceId);
          this.setState({pageVariation: pageVariation, customer: customer});
        }
        else {
            this.setState({pageVariation: pageVariation});
        }

      }

      this.props.actions.loadkeyFeatureList();
      this.props.actions.loadProcessSteps();
      this.props.actions.loadArticlesForHomePage();

      if(pageVariation == "full"){
          this.props.actions.getMenuForPage(pages.HOME);
          this.props.actions.loadProducts();
      }

      if (pageVariation === "newmember") {
        let extendedCustomer = parse(this.props.location.search);
        let hashSize = 42;

        if (extendedCustomer && extendedCustomer.ci && extendedCustomer.ch && extendedCustomer.ch.length === hashSize) {

          this.setState({ formLoading: true });

          this.props.actions.getExistingCustomer(extendedCustomer).then(e => {
            this.setState({ formLoading: false });
          }).catch(e => {this.setState({ formLoading: false });});
        }
      }

      this.props.actions.getTextVariants();
      this.props.actions.initUser();


      let tid = parse(this.props.location.search).transaction_id;

      if(tid){
        this.props.actions.trackReferralFromPartner(tid, null);
      }
    }

    componentDidUpdate(prevProps, prevState){


      if (prevProps && this.props && prevProps.existingCustomer && this.props.existingCustomer && (prevProps.existingCustomer.detail != this.props.existingCustomer.detail)) {
        let extendedCustomer = Object.assign({}, this.state.customer, this.props.existingCustomer.detail);
        this.setState({customer: extendedCustomer});
      }
    }

    onLoginClick(event){
         this.props.actions.trackButtonClick(pages.HOME + '-free-credit-score', 'login_splash');
         this.redirect(RoutesVariables.RouteLogin);
    }

    redirect(url) {
        this.props.history.push(url);
      }

    onGetScoreClick(event){

    //check the event properties to send tracking event

       if(this.props.isAuthenticated){
         this.redirect(RoutesVariables.RouteCreditScore);
       }
       else{
         this.props.actions.trackGetScoreButtonClick(event.target.id);
         //this.redirect('/test#registration-section');
       }
    }

    onLearnMoreClick(event){
       //this.redirect('/dashboard#dashboard-top-page');       
    }

    onProductClick(event, product){

      this.props.actions.trackGcsProductClick(product, pages.HOME, "free-credit-score");
    }

    onArticleClick(event, article){

        //event.preventDefault();
        //this.context.router.push('/article1/' + article.seoName);
    }

    onResourceClick(event, resourceId){

    }

    onRegistrationComplete(){
      this.props.history.push('/');
      return false;
    }

    onSaveCustomer(event) {
      event.preventDefault();
      const that = this;
      this.setState({saving: true});

        let validationResult = ValidationApi.validateCustomer(this.state.customer, this.state.omitFields);

      if (validationResult.isValid) {
        this.saveCustomerFromState(this.state.customer).then( r => {
          let jackMediaAppend = "";
          let tid = parse(this.props.location.search).transaction_id;

          if (tid) {
            jackMediaAppend = "?tid=" + tid;
          }

          this.props.history.push(RoutesVariables.RouteCheckYourInbox + (jackMediaAppend ? jackMediaAppend : ""));

        }).catch(e => {
          let errors = this.state.errors;
          let msg =  null;
          if(e.status == 409){
            msg = this.props.textVariants[pages.REGISTER + this.props.textVariants.textVariantSeparator + "409_startAgain"];
          }
          else if(e.status == 302){
            msg = this.props.textVariants[pages.REGISTER + this.props.textVariants.textVariantSeparator + "302_userExists"];
          }
          else if(e.status == 400){
            e.json().then(function(response) {
              response.forEach(error => {
                errors[error.field] = error.message;
              });

              that.setState({errors: errors, saving : false});
            });

            msg = this.props.textVariants[pages.REGISTER + this.props.textVariants.textVariantSeparator + "400_badRequest"];
          }
          else if(e.status == 500){
          msg = this.props.textVariants["Api_Generic"];
          }

          errors["global"] = msg;
          this.setState({errors: errors, saving : false});
        });
      } else {
        let newCustomer = this.state.customer;
        newCustomer.isCurrentEmailValid = validationResult.isCurrentEmailValid;
        newCustomer.isCurrentMobileValid = validationResult.isCurrentMobileValid;

        this.setState({customer: newCustomer, errors: validationResult.errors, saving: false});
      }
    }

    saveCustomerFromState(customer){
      var registeredCustomer = Object.assign({}, customer);
      //dob: { 'ddofbirth': '', 'mmofbirth': '', 'yyyyofbirth': '' }
      HelperApi.setInnerDateOfBirthAndDobFromNumbers(registeredCustomer, customer.dob.yy, customer.dob.mm, customer.dob.dd);

      //registeredCustomer.dateOfBirth.setTime( registeredCustomer.dateOfBirth.getTime() + registeredCustomer.dateOfBirth.getTimezoneOffset()*60*1000 );
      //registeredCustomer.email = this.props.userProfile.username; // || this.props.userProfile.rememberMeInfo.username;
      registeredCustomer.gender = customer.gender;

      let isExistingCustomer = this.state.pageVariation == "newmember";
      return this.props.actions.registerFull(registeredCustomer, isExistingCustomer);
    }

    updateCustomerState(event) {

        const field = event.target.name;

        let customer = this.state.customer;
        customer[field] = event.target.value;

        let errors = this.state.errors;
        errors[field] = field== 'dob' ? {} : '';

        return this.setState({ customer: customer, errors:errors });
    }

    updateCustomerServiceAddressError(event){

      const field = event.target.name;

      let errors = this.state.errors;

      if (event.target.status && event.target.status == 401) {
        //console.log("external service error code="+event.target.status);
      }

      errors[field] = this.props.textVariants["Error_cant_find_address"];

      return this.setState({ errors:errors });
    }

    getComponentList(){

      var components = [];

      if(this.state.pageVariation == "full" || this.state.pageVariation == "smart" || this.state.pageVariation == "toyota"){

          components.push(<HomeKeyFeaturesSection
             key="keyfeature"
             features={this.props.keyFeatureList}
             textVariants={this.props.textVariants}
             onLearnMoreClick={this.onLearnMoreClick}  />
           );

           if(this.state.pageVariation == "full" ){

              components.push(<ProductSection
                 key="products"
                 loading={this.props.productLoading}
                 products={this.props.products}
                 textVariants={this.props.textVariants}
                 onProductClick={this.onProductClick}
                 title={this.props.textVariants[pages.HOME + this.props.textVariants.textVariantSeparator + "product_header"]}
                 disclaimer={this.props.textVariants[pages.HOME + this.props.textVariants.textVariantSeparator + "product_disclaimer"]} />
               );
           }

            components.push(<SectionFaq key="faqsection" faqs={staticSectionFAQS} />);
       }

        return components;

    }

  render() {
    let componentToBeRendered, registrationForm;
    componentToBeRendered = registrationForm = (
      <RegistrationForm
        onSave={this.onSaveCustomer}
        onChange={this.updateCustomerState}
        errors={this.state.errors}
        saving={this.state.saving}
        customer={this.state.customer}
        onServiceError={this.updateCustomerServiceAddressError}
        textVariants={this.props.textVariants}
        omitFields={this.state.omitFields}
        saveText={this.props.textVariants["Btn_GetMyScoreNow"]}
        pageVariation={this.state.pageVariation}
      />
    );

    // Get members back section ------------------
    if (this.state.pageVariation == "newmember") {
      let hasError = this.props.existingCustomer && this.props.existingCustomer.hasError;

      if (this.state.formLoading) {
        componentToBeRendered = <NewMemberProgressBar />;

      } else {
        if (hasError) {
          componentToBeRendered = (
            <div>
              <NewMemberErrorSection textVariants={this.props.textVariants} />
              {registrationForm}
            </div>
          );
        }
      }
    }
    // End Get members back section ------------------

    return (
      <div className="homePage">

        {/* <div>This should be {this.props.route.something}</div> */}

        <HomeSplashSection
          cssClass="homesplash-old-style"
          textVariants={this.props.textVariants}
          menuItemList={this.props.menuItemList}
        >
          <div>
            <HomeSplashCallToActionPlaceholder
              onGetScoreClick={this.onGetScoreClick}
              onLoginClick={this.onLoginClick}
              isAuthenticated={this.props.isAuthenticated}
              textVariants={this.props.textVariants}
              userProfile={this.props.userProfile}
              originPage={pages.FREECREDITSCORE}
              location={this.props.location}
              pageVariation={this.state.pageVariation}
            />

            <HomeSplashFeaturedOnSection  textVariants={this.props.textVariants} />

            <CounterSection
              key="scorecounter-transparent"
              counterSectionTheme="counter-section-theme-transparent counter-section-theme-xsmall-transparent"
              forMobile
            />
          </div>
        </HomeSplashSection>

        <TrustPilotSection
          key="trustPilot"
          isEnabled={this.props.isTrustPilotEnabled}
          type="inline-minimal"
          trustPilotSectionTheme={this.state.trustPilotSectionTheme}
          textVariants={this.props.textVariants} />

        <div className="registration" id="registration-section">
          {/* <RegistrationTitleSectionBlue textVariants={this.props.textVariants} /> */}

          {componentToBeRendered}          

        </div>

        {this.getComponentList()}
      </div>
    );
  }
}

HomeOldStylePage.contextTypes = {
  router: PropTypes.object
};


function mapStateToProps(state, ownProps) {


  var menuItems = [];
  var keyFeatureList = [];
  var processSteps = {};
  var articles = [];
  let products = [];
  let txtVariants = {};

  if(state.menu){
    var menu = state.menu;
    if( menu.menuItemsPerPage && menu.menuItemsPerPage.menuItems && menu.menuItemsPerPage.menuItems[pages.HOME]){
        menuItems = menu.menuItemsPerPage.menuItems[pages.HOME];
     }
   }

   if(state.homePage && state.homePage.keyFeatureList){
     keyFeatureList = state.homePage.keyFeatureList;
   }

   if(state.homePage && state.homePage.processSteps){
     processSteps = state.homePage.processSteps;
   }

   if(state.homePage && state.homePage.articles){
     articles = state.homePage.articles;
   }

   let productLoading = null;
   if(state.productResult && state.productResult.products){
     products = state.productResult.products;
     productLoading = state.productResult.loading;
   }

   if(state.textVariants){
     txtVariants = state.textVariants;
   }

   let isAnonymous = true;
   if(state.productResult){
     isAnonymous = state.productResult.isAnonymous;
   }

   let userProfile = {};
   if (state.userProfile) {
    userProfile = state.userProfile;
   }

  return {
    products : products,
    productLoading: productLoading,
    isAnonymous: isAnonymous,
    rememberMe: state.userProfile.rememberMeInfo.rememberMe,
    isAuthenticated: state.userProfile.isAuthenticated,
    menuItemList: menuItems,
    keyFeatureList: keyFeatureList,
    articles: articles,
    processSteps: processSteps,
    textVariants: txtVariants,
    userProfile: state.userProfile,
    existingCustomer: userProfile.existingCustomer,
    isTrustPilotEnabled: userProfile.isTrustPilotEnabled,
    registeringUsername: state.userProfile.registeringUsername
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators( Object.assign({}, productActions, layoutActions, homePageActions, userActions, trackingActions) , dispatch)
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(HomeOldStylePage);
