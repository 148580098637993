import delay from './delay';
import * as GlobalVariables from '../config/configureGlobalVariables';
//import EventTrackingHelper from './EventTrackingHelper';

import * as mcolour from '../common/dvsMedicare';

class ValidationApi {


  static validateEmailInivio(email) {
      const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/validate/email';

      const options = {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
        'Content-Type': 'application/json'//,
        //'x-gcs-xsrf': csrfToken
      },
       body: JSON.stringify({email: email})
      };

      return fetch(fullUrl, options).then(response =>
      {
          if (!response.ok) {
            return Promise.reject(response);
          }
          else{
            return response.json();
          }
      }).catch(function (error) {
        throw(error);
      });
    }

    static validateMobileInivio(mobile) {
        const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/validate/mobile';

        const options = {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: {
          'Content-Type': 'application/json'//,
          //'x-gcs-xsrf': csrfToken
        },
         body: JSON.stringify({mobile: mobile})
        };

        return fetch(fullUrl, options).then(response =>
        {
            if (!response.ok) {
              return Promise.reject(response);
            }
            else{
              return response.json();
            }
        }).catch(function (error) {
          throw(error);
        });
      }


  static validateCustomerPromise(customer, omitFields) {

      var validateCustomerResults = ValidationApi.validateCustomer(customer, omitFields);

      validateCustomerResults.isCurrentEmailValid = customer["isCurrentEmailValid"];
      validateCustomerResults.isCurrentMobileValid = customer["isCurrentMobileValid"];

      if(!validateCustomerResults.isValid && validateCustomerResults.errors && (validateCustomerResults.errors.email || validateCustomerResults.errors.mobile)){
        return Promise.resolve(validateCustomerResults);
      }
      else{
            var validationsPromise = [];

            if(!omitFields || !omitFields["email"]) {
              //validateCustomerResults.isCurrentEmailValid = customer["isCurrentEmailValid"];
              if(!validateCustomerResults.errors.email && !customer["isCurrentEmailValid"]){
                    validateCustomerResults.isCurrentEmailValid = false;
                    var emailPromise = ValidationApi.validateEmailInivio(customer.email).then( emailResult =>
                    {
                        validateCustomerResults.isValid = validateCustomerResults.isValid && emailResult.isValid;

                        if(!emailResult.isValid){
                            validateCustomerResults.errors.email = 'Email is invalid';

                            //EventTrackingHelper.trackAccountEmailValidation();
                        }
                        else{
                          validateCustomerResults.isCurrentEmailValid = true;
                        }

                        return validateCustomerResults;
                  });

                  validationsPromise.push(emailPromise);
            }
          }

          if ((!omitFields || !omitFields["mobile"])) {
            //validateCustomerResults.isCurrentMobileValid = customer["isCurrentMobileValid"];
           if(!validateCustomerResults.errors.mobile && !customer["isCurrentMobileValid"]){

                 validateCustomerResults.isCurrentMobileValid = false;
                 var mobilePromise = ValidationApi.validateMobileInivio(customer.mobile).then( mobileResult =>
                 {
                     validateCustomerResults.isValid = validateCustomerResults.isValid && mobileResult.isValid;

                     if(!mobileResult.isValid){
                         validateCustomerResults.errors.mobile = 'Mobile number seems invalid';

                         //EventTrackingHelper.trackAccountMobileValidation();
                     }
                     else{
                      validateCustomerResults.isCurrentMobileValid = true;
                     }

                     return validateCustomerResults;
                });

                validationsPromise.push(mobilePromise);
         }
        }

         if(validationsPromise.length > 0){

           return Promise.all(validationsPromise).then( values => {
              let returnValidation = {};

              returnValidation = Object.assign(returnValidation, ...values);

              return returnValidation;
           });
         }
         else {
            return Promise.resolve(validateCustomerResults);
         }
     }
  }

  static validateCustomer(customer, omitFields) {

      let formIsValid = true;
      let firstElementName = null;

      let errors = { dob: { dd: '', mm: '', yy: '' } };

      if(!omitFields || !omitFields["firstName"]){
        let errorFirstName = ValidationApi.isFirstNameValid("First name", customer.firstName, 40);
        if (errorFirstName) {
            errors.firstName = errorFirstName;
            formIsValid = false;
            if(firstElementName == null){firstElementName = 'firstName';}
        }
      }

      if(!omitFields || !omitFields["middleName"]){
        let errorMiddleName = ValidationApi.isNonRequiredNameFieldValid('Middle name', customer.middleName, 80);
        if (errorMiddleName) {
            errors.middleName = errorMiddleName;
            formIsValid = false;
            if(firstElementName == null){firstElementName = 'middleName';}
        }
      }

      if(!omitFields || !omitFields["lastName"]){
        let errorLastName = ValidationApi.isLastNameValid('Last name', customer.lastName, 50);
        if (errorLastName) {
            errors.lastName = errorLastName;
            formIsValid = false;
            if(firstElementName == null){firstElementName = 'lastName';}
        }
      }

      if (!omitFields || !omitFields["mobile"]) {
          let errorMobile = ValidationApi.validateMobile(customer.mobile);
          if (errorMobile) {
              errors.mobile = errorMobile;
              formIsValid = false;
              if (firstElementName == null) { firstElementName = 'mobile'; }
          }
      }

      if(!omitFields || !omitFields["email"]){
          let errorEmail = ValidationApi.validateEmail(customer.email);
          if (errorEmail) {
              errors.email = errorEmail;
              formIsValid = false;
              if(firstElementName == null){firstElementName = 'email';}
          }
      }

      if(!omitFields || !omitFields["dob"]){
        let errorDDOfBirth = ValidationApi.requiredField('Day of birth', customer.dob["dd"]);
        if (errorDDOfBirth) {
            errors.dob["dd"] = errorDDOfBirth;
            formIsValid = false;
            if(firstElementName == null){firstElementName = 'dob';}
        }

        let errorMMOfBirth = ValidationApi.requiredField('Month of birth', customer.dob["mm"]);
        if (errorMMOfBirth) {
            errors.dob["mm"] = errorMMOfBirth;
            formIsValid = false;
            if(firstElementName == null){firstElementName = 'dob';}
        }
  
        let errorYYYYOfBirth = ValidationApi.requiredField('Year of birth', customer.dob["yy"]);
        if (errorYYYYOfBirth) {
            errors.dob["yy"] = errorYYYYOfBirth;
            formIsValid = false;
            if(firstElementName == null){firstElementName = 'dob';}
        }
  
        let errorDate = ValidationApi.validateDOB(customer.dob["dd"] + "/" + customer.dob["mm"] + "/" + customer.dob["yy"]);
        if (errorDate) {
          errors.dob.dateError = errorDate;
          formIsValid = false;
          if(firstElementName == null){firstElementName = 'dob';}
        }
  
      }        

      if(!omitFields || !omitFields["address"]){
        let errorAddress = ValidationApi.requiredField('Address', customer.address);

        if (!errorAddress) {
          errorAddress = ValidationApi.validateAddressLengthdField('Address', customer.address, 20, 300);
          if(errorAddress){
            errors.address = errorAddress;
            formIsValid = false;
            if(firstElementName == null){firstElementName = 'address';}
          }
          else{
                errorAddress = ValidationApi.validateAddressState('Address', customer.address);
                if(errorAddress){
                  errors.address = errorAddress;
                  formIsValid = false;
                  if(firstElementName == null){firstElementName = 'address';}
                }
          }
        }
        else{
            errors.address = errorAddress;
            formIsValid = false;
            if(firstElementName == null){firstElementName = 'address';}
        }

        if (customer.hasLivedLessThan3Years) {
            let errorPrevAddress = ValidationApi.requiredField('Previous address', customer.addressPrevious);
                if (!errorPrevAddress) {
                  errorPrevAddress = ValidationApi.validateAddressLengthdField('Address', customer.addressPrevious, 20, 300);
                  if(errorPrevAddress){
                    errors.addressPrevious = errorPrevAddress;
                    formIsValid = false;
                    if(firstElementName == null){firstElementName = 'addressPrevious';}
                  }
                  else{
                        errorPrevAddress = ValidationApi.validateAddressState('Address', customer.addressPrevious);
                        if(errorPrevAddress){
                          errors.addressPrevious = errorPrevAddress;
                          formIsValid = false;
                          if(firstElementName == null){firstElementName = 'addressPrevious';}
                        }
                  }
                }
                else{
                    errors.addressPrevious = errorPrevAddress;
                    formIsValid = false;
                    if(firstElementName == null){firstElementName = 'addressPrevious';}
                }
        }
      }

      // let errorMobile = ValidationApi.validateMobile(customer.mobile);
      // if (errorMobile) {
      //     errors.mobile = errorMobile;
      //     formIsValid = false;
      //     if(firstElementName == null){firstElementName = 'mobile';}
      // }

      // if(customer.driversLicence){
      //   let errorLicence = ValidationApi.validateDriverLicence(customer.driversLicence);
      //   if (errorLicence) {
      //       errors.driversLicence = errorLicence;
      //       formIsValid = false;
      //       if(firstElementName == null){firstElementName = 'driversLicence';}
      //   }
      // }

      if(!omitFields || !omitFields["gender"]){
        let errorGender = ValidationApi.validateGender(customer.gender);
        if (errorGender) {
            errors.gender = errorGender;
            formIsValid = false;
            if(firstElementName == null){firstElementName = 'gender';}
        }
      }

      if(!omitFields || !omitFields["password"]){
                let errorPassword = ValidationApi.validatePassword(customer.password);
                if (errorPassword) {
                    errors.password = errorPassword;
                    formIsValid = false;
                    if(firstElementName == null){firstElementName = 'password';}
                }
      }

      if(!omitFields || !omitFields["consent"]){        
        if (!customer.consent) {
            errors.consent = "Please select this field to continue";
            formIsValid = false;            
        }
      }

      errors.hasError = !formIsValid;
      errors.firstOne = firstElementName;
      return {
        isValid : formIsValid,
        errors: errors
      };
  }

    static validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var result = "";

      if (!re.test(email)) {
       result =  'Email is invalid';
      }

      return result;
    }

    static validatePassword(password) {

      if(password){
        return "";
      }
      else{
        return "Password is required";
      }

      // at least one number, one lowercase and one uppercase letter
      // at least six characters
      var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;

      if (!re.test(password))
        return "Password should contain one number, one lowercase, one uppercase letter and to be at least 6 characters";

      else
        return '';
    }

    static validateDOB(dateString) {

        if (!ValidationApi.validateDate(dateString)) {
            return "This date is invalid";
        }

        if (!ValidationApi.validateAge(dateString)) {
            return "You need to be 18 years or older";
        }

        return "";
    }

    static  validateDate(dateString) {
        let monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        let currentYear = (new Date).getFullYear();

        let parts = dateString.split("/"),
            day = parseInt(parts[0], 10),
            month = parseInt(parts[1], 10),
            year = parseInt(parts[2], 10);

        if (isNaN(day) || isNaN(month) || isNaN(year)) {
            return false;
        }


        if (year < 1900 || year > currentYear || month < 1 || month > 12) {
            return false;
        }

        // Adjust for leap years
        if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
            monthLength[1] = 29;
        }

        // Check the range of the day
        if (day <= 0 || day > monthLength[month - 1]) {
            return false;
        }

        return true;
    }

    static validateAge(dateString) {
        let parts = dateString.split("/"),
            day = parseInt(parts[0], 10),
            month = parseInt(parts[1], 10),
            year = parseInt(parts[2], 10);

        //18 years and above check
        let date = new Date(year + 18, month - 1, day);

        if (date > new Date()) {
            return false;
        }

        return true;
    }

    static validateGender(gender) {
        if (gender == null)
            return 'Gender is required.';
    }

     //// Old Logic Before DVS Change
  //   static validateDriverLicence(licenceNumber) {

  //       if (!licenceNumber) {
  //           return "Driver licence number is required";
  //       }

  //      let licenceNumberLength = licenceNumber.length;

  //       // check if 3rd and 4th character are num.
  //      let sliceCheck = function () {
  //          let numberSliced = licenceNumber.slice(2, 4);
  //          let numberMatch = numberSliced.match(/\d+/g);
  //           if (numberMatch) {
  //               if (numberMatch.join('').length == 2) {
  //                   return true;
  //               }
  //               return false;
  //           }
  //           return false;
  //       };

  //       //check numbers
  //      let licenceNumberCheck = function () {
  //          let numberMatch = licenceNumber.match(/\d+/g);
  //           if (numberMatch) {
  //               if (numberMatch.join('').length >= 4) {
  //                   return true;
  //               }
  //               return false;
  //           }
  //           return false;
  //       };

  //       //check alfa
  //      let licenceAlphaCheck = function () {
  //          let alfaMatch = licenceNumber.match(/[a-zA-Z]+/g);
  //           if (alfaMatch) {
  //               if (alfaMatch.join('').length <= 2) {
  //                   return true;
  //               }
  //               return false;
  //           }
  //           return true;
  //       };

  //       //check if is only alfaNumeric
  //      let licenceAlphaNumericCheck = function () {
  //          let specialCharacters = /[^a-zA-Z0-9]/.test(licenceNumber);
  //           if (!specialCharacters) {
  //               return true;
  //           }
  //           return false;
  //       };

  //       if (parseInt(licenceNumber, 10) == 0) return "Driver licence number is invalid.";

  //       if (licenceNumberLength >= 4 && licenceNumberLength <= 9 && licenceAlphaCheck() && licenceNumberCheck() && sliceCheck() && licenceAlphaNumericCheck()) {
  //           return  '';

  //       }
  //       return "Driver licence number is invalid.";
  //  }

  //// New Logic for DVS change
  static validateDriverLicence(licenceNumber, stateCode) {

    if (!licenceNumber) {
        return "Driver licence number is required";
    }

   let licenceNumberLength = licenceNumber.length;

  //   // check if 3rd and 4th character are num.
  //  let sliceCheck = function () {
  //      let numberSliced = licenceNumber.slice(2, 4);
  //      let numberMatch = numberSliced.match(/\d+/g);
  //       if (numberMatch) {
  //           if (numberMatch.join('').length == 2) {
  //               return true;
  //           }
  //           return false;
  //       }
  //       return false;
  //   };

  //   //check numbers
  //  let licenceNumberCheck = function () {
  //      let numberMatch = licenceNumber.match(/\d+/g);
  //       if (numberMatch) {
  //           if (numberMatch.join('').length >= 4) {
  //               return true;
  //           }
  //           return false;
  //       }
  //       return false;
  //   };

  //   //check alfa
  //  let licenceAlphaCheck = function () {
  //      let alfaMatch = licenceNumber.match(/[a-zA-Z]+/g);
  //       if (alfaMatch) {
  //           if (alfaMatch.join('').length <= 2) {
  //               return true;
  //           }
  //           return false;
  //       }
  //       return true;
  //   };

    //check if is only alfaNumeric
   let licenceAlphaNumericCheck = function () {
       let specialCharacters = /[^a-zA-Z0-9]/.test(licenceNumber);
        if (!specialCharacters) {
            return true;
        }
        return false;
    };

    //check if is only Numeric
   let licenceNumericCheck = function () {
       let specialCharacters = /[^0-9]/.test(licenceNumber);
        if (!specialCharacters) {
            return true;
        }
        return false;
    };

    // if (parseInt(licenceNumber, 10) == 0) return "Driver licence number is invalid.";

        //StateWise Validation
        if ((stateCode == "ACT") || (stateCode == "NT") || (stateCode == "VIC"))
        {
          if (licenceNumberLength >= 1 && licenceNumberLength <= 10 && licenceNumericCheck()) 
          {
              return  '';
          }
        }
    
        if (stateCode == "SA")
        {
          if (licenceNumberLength == 6 && licenceAlphaNumericCheck()) 
          {
              return  '';
          }
        }
    
        if (stateCode == "WA") 
        {
          if (licenceNumberLength == 7 && licenceNumericCheck()) 
          {
              return  '';
          }
        }
    
        if (stateCode == "QLD")
        {
          if (licenceNumberLength >= 8 && licenceNumberLength <= 9 && licenceNumericCheck()) 
          {
              return  '';
          }
        }
    
        if ((stateCode == "TAS") || (stateCode == "NSW"))
        {
          if (licenceNumberLength >= 6 && licenceNumberLength <= 8 && licenceAlphaNumericCheck()) 
          {
              return  '';
          }
        }

    // if (licenceNumberLength >= 4 && licenceNumberLength <= 9 && licenceAlphaCheck() && licenceNumberCheck() && sliceCheck() && licenceAlphaNumericCheck()) {
    //     return  '';

    // }
    return "Driver licence number is invalid.";
}

 //// New Addition for DVS change
   static validatecardNumber(cardNumber, stateCode) {

    if (!cardNumber) {
        return "Card number is required";
    }

   let cardNumberLength = cardNumber.length;

  //   // check if 3rd and 4th character are num.
  //  let sliceCheck = function () {
  //      let numberSliced = cardNumber.slice(2, 4);
  //      let numberMatch = numberSliced.match(/\d+/g);
  //       if (numberMatch) {
  //           if (numberMatch.join('').length == 2) {
  //               return true;
  //           }
  //           return false;
  //       }
  //       return false;
  //   };

  //   //check numbers
  //  let licenceNumberCheck = function () {
  //      let numberMatch = cardNumber.match(/\d+/g);
  //       if (numberMatch) {
  //           if (numberMatch.join('').length >= 4) {
  //               return true;
  //           }
  //           return false;
  //       }
  //       return false;
  //   };

  //   //check alfa
  //  let licenceAlphaCheck = function () {
  //      let alfaMatch = cardNumber.match(/[a-zA-Z]+/g);
  //       if (alfaMatch) {
  //           if (alfaMatch.join('').length <= 2) {
  //               return true;
  //           }
  //           return false;
  //       }
  //       return true;
  //   };

    //check if is only alfaNumeric
   let cardNumberAlphaNumericCheck = function () {
       let specialCharacters = /[^a-zA-Z0-9]/.test(cardNumber);
        if (!specialCharacters) {
            return true;
        }
        return false;
    };

   //check if is only Numeric
   let cardNumberNumericCheck = function () {
       let specialCharacters = /[^0-9]/.test(cardNumber);
        if (!specialCharacters) {
            return true;
        }
        return false;
    };

    // if (parseInt(cardNumber, 10) == 0) return "Card number is invalid.";
    //StateWise Validation
    if ((stateCode == "TAS") || (stateCode == "SA"))
    {
      if (cardNumberLength == 9 && cardNumberAlphaNumericCheck()) 
      {
          return  '';
      }
    }

    if (stateCode == "NSW")
    {
      if (cardNumberLength == 10 && cardNumberNumericCheck()) 
      {
          return  '';
      }
    }

    if (stateCode == "ACT") 
    {
      if (cardNumberLength == 10 && cardNumberAlphaNumericCheck()) 
      {
          return  '';
      }
    }

    if (stateCode == "WA")
    {
      if (cardNumberLength >= 8 && cardNumberLength <= 10 && cardNumberAlphaNumericCheck()) 
      {
          return  '';
      }
    }

    if (stateCode == "NT")
    {
      if (cardNumberLength >= 6 && cardNumberLength <= 8 && cardNumberNumericCheck()) 
      {
          return  '';
      }
    }
     if (stateCode == "QLD") 
    {
      if (cardNumberLength == 10 && cardNumberAlphaNumericCheck()) 
      {
          return  '';
      }
    }
    if (stateCode == "VIC") 
    {
      if (cardNumberLength == 8 && cardNumberAlphaNumericCheck()) 
      {
          return  '';
      }
    }

    // if (cardNumberLength >= 4 && cardNumberLength <= 9 && licenceAlphaCheck() && licenceNumberCheck() && sliceCheck() && licenceAlphaNumericCheck()) 
    // {
    //     return  '';
    // }
    return "Card number is invalid.";
}


    static requiredAlphabeticalField(fieldName, fieldValue) {
        let result = '';

        if (!fieldValue) {
            result = fieldName + ' is required.';
        }

        else if (!ValidationApi.isChar(fieldValue)) {
            result += fieldName +' should contains letters only.';
        }

        return result;
    }

    static isFirstNameValid(fieldName, fieldValue, maxLength) {
      return ValidationApi.isNameFieldValid(fieldName, fieldValue, maxLength, true, true);
    }

    static isLastNameValid(fieldName, fieldValue, maxLength) {
      return ValidationApi.isNameFieldValid(fieldName, fieldValue, maxLength, false, true);
    }

    static isNonRequiredNameFieldValid(fieldName, fieldValue, maxLength) {
      return ValidationApi.isNameFieldValid(fieldName, fieldValue, maxLength, true, false);
    }

    static isNameFieldValid(fieldName, fieldValue, maxLength, shouldBeginWithAlpha, isRequired) {
      if (!isRequired && !fieldValue) {
        return "";
      }
      
      if (isRequired && !fieldValue) {
        return fieldName + " is required.";
      }

      fieldValue.trim();

      if (fieldValue.length > maxLength) {
        return `${fieldName} cannot be longer than ${maxLength} characters.`;
      }

      let regexValidator;

      if (shouldBeginWithAlpha) {
        regexValidator = new RegExp("^(?!.*([-|\\s|'|’][-|\\s|'|’]+))[a-zA-Z][a-zA-Z\\s'-]*$");
      } else {
        regexValidator = new RegExp("^(?!.*([-|\\s|'|’][-|\\s|'|’]+))([a-zA-Z]|'[a-zA-Z])[a-zA-Z\\s'-]*$");
      }
    
      if (!regexValidator.test(fieldValue)) {
        return fieldName + " contains invalid characters.";
      }
      
      return "";
    }

    static requiredNameField(fieldName, fieldValue) {
        let result = '';

        // check if field is not empty
        if (!fieldValue) {
            result = fieldName + ' is required.';
            return result;
        }

        // check if field contains  not only spaces, apostrophes or hyphons
        let updatedValue = fieldValue.replace(/\s+/g, '');
        updatedValue = updatedValue.replace(/\-+/g, '');
        updatedValue = updatedValue.replace(/\'+/g, '');
        if (updatedValue.length == 0) {
          result =  fieldName + " contains invalid characters";
          return result;
        }

        // check other characters
        var re = /^[a-z -'-']+$/i;

        if (!re.test(fieldValue))
          result =  fieldName + " contains invalid characters";

        return result;
    }

    static nameField(fieldName, fieldValue) {
        let result = '';

        if (!fieldValue) return;

        // check if field contains  not only spaces, apostrophes or hyphons
        let updatedValue = fieldValue.replace(/\s+/g, '');
        updatedValue = updatedValue.replace(/\-+/g, '');
        updatedValue = updatedValue.replace(/\'+/g, '');
        if (updatedValue.length == 0) {
          result =  fieldName + " contains invalid characters";
          return result;
        }

        // check other characters
        var re = /^[a-z -'-']+$/i;

        if (!re.test(fieldValue))
          result =  fieldName + " contains invalid characters";

        return result;
    }

    static alphabeticalField(fieldName, fieldValue) {
        let result = '';

        if (fieldValue) {
          if (!ValidationApi.isChar(fieldValue)) {
              result += fieldName +' should contains letters only.';
          }
        }

        return result;
     }

        static isNumber(n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        }

        static isInteger(value) {
            return typeof value === 'number' &&
                    isFinite(value) &&
                    Math.floor(value) === value;
        }

        static isChar(str) {
            return /^[a-zA-Z]+$/.test(str);
        }

        static isMobile(number) {
           if(number){
            number = number.replace(/\s+/g, '');
            return  /^\d{10}$/.test(number) ;
          }
          return false;
        }

        static validateMobile(mobile){
            let result = '';
            if (!mobile) {
                result = 'Mobile number is required.';
            }
            else if (!ValidationApi.isMobile(mobile)) {
                result = 'Mobile number should contain valid 10 digits only.';
            }

            return result;
        }

        static isValidPostCode(postCode) {
            const postCodeLength = 4;
            const value = Number(postCode);
            const valueIsInteger = ValidationApi.isInteger(value);
            const valueHasProperLength = postCode && postCode.length === postCodeLength;

            return valueIsInteger && valueHasProperLength;
        }

        static requiredField(fieldName, fieldValue) {
            let result = '';

            if (!fieldValue) {

                result = fieldName + ' is required.';
            }

            return result;
        }

        static validateLengthdField(fieldName, fieldValue, minLength, maxLength) {
            let result = '';

            if (fieldValue) {
                let l = ('' + fieldValue).length;
                if(minLength){
                  if(l < minLength){
                      result += fieldName + ' is less than ' + minLength + ' characters';
                  }
                }

                if(maxLength){
                  if(l > maxLength){
                      result += fieldName + ' is more than ' + maxLength + ' characters';
                  }
                }
            }

            return result;
        }

        static validateAddressLengthdField(fieldName, fieldValue, minLength, maxLength) {
            let result = '';

            if (fieldValue) {
                let l = ('' + fieldValue).length;
                if(minLength){
                  if(l < minLength){
                      result += fieldName + ' is less than ' + minLength + ' characters. Please select from the list, otherwise enter it in the format of "12 smith street, Sydney NSW 2000"';
                  }
                }

                if(maxLength){
                  if(l > maxLength){
                      result += fieldName + ' is more than ' + maxLength + ' characters. Please select from the list, otherwise enter it in the format of "12 smith street, Sydney NSW 2000"';
                  }
                }
            }

            return result;
        }

        static validateAddressState(fieldName, fieldValue) {
            let result = '';

            if (fieldValue) {
                let l = ('' + fieldValue);
                if( !(l.indexOf('NSW') >=0 ||
                    l.indexOf('VIC') >=0 ||
                    l.indexOf('NT') >=0  ||
                    l.indexOf('TAS') >=0 ||
                    l.indexOf('WA') >=0  ||
                    l.indexOf('QLD') >=0 ||
                    l.indexOf('SA') >=0  ||
                    l.indexOf('ACT') >=0)
                   ){

                  result += fieldName + ' needs to include the state: NSW, VIC, NT, TAS, WA, QLD, SA, ACT';
                }
            }

            return result;
        }

        static validateIdentityDocuments(csrfToken, validationRequest) {
          const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/validate/identity-documents';
        
          const options = {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
            'Content-Type': 'application/json',
            'x-gcs-xsrf': csrfToken
          },
           body: JSON.stringify(validationRequest)
          };
        
          return fetch(fullUrl, options).then(response =>
          {
              if (!response.ok) {
                return Promise.reject(response);
              }
              else{
                return response.json();
              }
          }).catch(function (error) {
            throw(error);
          });
        }

        static getIdentityDocumentsValidation(csrfToken, validationRequest) {
          const  fullUrl = GlobalVariables.API_BASE_URL + 'v1/validate/identity-documents';        
          const options = {
            method: 'PUT',
            mode: 'cors',
            credentials: 'include',
            headers: {
            'Content-Type': 'application/json',
            'x-gcs-xsrf': csrfToken
          },
           body: JSON.stringify(validationRequest)
          };
        
          return fetch(fullUrl, options).then(response =>
          {            
              if (!response.ok) {
                return Promise.reject(response);
              }
              else{
                return response.json();
              }
          }).catch(function (error) {            
            throw(error);
          });
        }

        static validateIdentityRequestDLPromise(dlRequest){

          let errors = {};
          let formIsValid = true;

          if(!dlRequest.consent){
            errors.consent = 'Consent is mandatory';
            formIsValid = false;   
          }

          if(!dlRequest.consent1){
            errors.consent1 = 'Consent is mandatory';
            formIsValid = false;   
          }

         let errorLicence = ValidationApi.validateDriverLicence(dlRequest.number, dlRequest.stateCode);
          if (errorLicence) {
              errors.number = errorLicence;
              formIsValid = false;              
          }



          // let errorCardNumber = ValidationApi.validatecardNumber(dlRequest.cardNumber);
          // if (errorCardNumber) {
          //   if ((dlRequest.stateCode == "VIC") || (dlRequest.stateCode == "QLD"))
          //   {
          //      errors.cardNumber  = {};
          //   }
          //   else{
          //     errors.cardNumber = errorCardNumber;
          //     formIsValid = false;   
          //   }           
          // }

          
            let errorCardNumber = ValidationApi.validatecardNumber(dlRequest.cardNumber, dlRequest.stateCode);
            if (errorCardNumber) {
              errors.cardNumber = errorCardNumber;
              formIsValid = false;   
            }          
          

          errors.stateCode = ValidationApi.validateAddressState("stateCode", dlRequest.stateCode);

          errors.hasError = !formIsValid;          
          return Promise.resolve({
            isValid : formIsValid,
            errors: errors
          });
      }

      static validateIdentityRequestMedicarePromise(medicareRequest){
        
        let errors = {};
        let formIsValid = true;

        if(!medicareRequest.consent){
          errors.consent = 'Consent is mandatory';
          formIsValid = false;   
        }

        if(!medicareRequest.consent1){
          errors.consent1 = 'Consent is mandatory';
          formIsValid = false;   
        }

        let errorNumber = ValidationApi.isNumber(medicareRequest.number);
        if (!errorNumber) {
            errors.number = 'Number is invalid';
            formIsValid = false;              
        }
        
        let errorReferenceNumber = ValidationApi.isNumber(medicareRequest.referenceNumber);
        if (!errorReferenceNumber) {
          errors.referenceNumber = 'Reference number is invalid';
          formIsValid = false;              
        }

        // console.log('medicareCardColour', mcolour.medicareCardColour);
        // console.log('medicareCardColour', mcolour.medicareCardColour.green);
        if ((medicareRequest.cardColour != mcolour.medicareCardColour.green) &&
            (medicareRequest.cardColour != mcolour.medicareCardColour.blue) &&
            (medicareRequest.cardColour != mcolour.medicareCardColour.yellow)){ 
          errors.cardColour = "Invalid card colour";
          formIsValid = false;              
        }

        if (!medicareRequest.dateOfExpiry ||
            !medicareRequest.dateOfExpiry.mm ||
            !medicareRequest.dateOfExpiry.yy
          ) {              
                errors.dateOfExpiry = {
                  dateError: "Expiry Date does not seem right. In some browsers you need to input the date as YYYY-MM (or YYYY-MM-DD depending on your medicare card) (year 4 digits, dash, month 4 digits and an optional 2 digits day)"
             };
          formIsValid = false;              
        }
      
        errors.hasError = !formIsValid;          
        return Promise.resolve({
          isValid : formIsValid,
          errors: errors
        });
    }

    static validateIdentityRequestPassportPromise(passportRequest){

          let errors = {};
          let formIsValid = true;     
          
          if(!passportRequest.consent){
            errors.consent = 'Consent is mandatory';
            formIsValid = false;   
          }

          if(!passportRequest.consent1){
            errors.consent1 = 'Consent is mandatory';
            formIsValid = false;   
          }

          if (!passportRequest.number || passportRequest.number.length > 20) {
              errors.number = "The passport number seems incorrect";
              formIsValid = false;
          }
          
          errors.hasError = !formIsValid;          
          return Promise.resolve({
            isValid : formIsValid,
            errors: errors
          });
    }
}

export default ValidationApi;
